import { COMMON_ERRORS } from 'constants/text';

import { post } from './fetch';
import { emailRegExp } from './user';


export async function confirmEmailByHash(hash: string): Promise<number> {
	const data = new FormData();
	data.append('hash', hash);

	try {
		const response = await fetch('/api/v1/user/do_email_confirmation/', {
			credentials: 'same-origin',
			method: 'POST',
			body: data,
		});
		return response.status;
	} catch (err) {
		return 400;
	}
}

export async function confirmEmail(
	email: string,
	setCode?: (c: number) => void,
	setStatus?: (s: string) => void,
): Promise<void> {
	if (!emailRegExp.test(email)) {
		setCode?.(-1);
		setStatus?.(COMMON_ERRORS.EMAIL);
		return;
	}

	try {
		const response = await post('/api/v1/user/request_email_confirm/', { email });
		setCode?.(response.status);
		if (response.status === 200) {
			setStatus?.('На почту было прислано письмо с ссылкой, которая будет активна в течение 24 часов.');
			return;
		}
		if (response.status === 409) {
			setStatus?.('Эта почту уже подтверждена другим человеком');
			return;
		}
		setStatus?.('Не удалось отправить письмо с подтверждением на почту');
	} catch (err) {
		setStatus?.('Не удалось отправить письмо с подтверждением на почту');
	}
}

export async function cancelCurrentScanOperation(operationId: number): Promise<void | Error> {
	const response = await post('/api/scan_operations/mark_as_finished/', { operation_id: operationId });
	if (!response.ok) {
		return new Error('Не удалось сбросить текущее сканирование');
	}
}

