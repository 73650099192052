import { DAYS } from 'constants/common';

import { OpenHours } from 'types/common';

export function getDateOneMonthAgo(date: Date): Date {
	const result = new Date(date.getTime());
	result.setMonth(date.getMonth() - 1);
	return result;
}

export function isTimeBetween(startHour, startMinute, endHour, endMinute) {
	// Get the current time in UTC
	const now = new Date(Date.now());

	// Get the current time in CET (Central European Time)
	const currentCETTime = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Moscow' }));

	// Extract the current hour and minute in CET
	const currentHour = currentCETTime.getHours();
	const currentMinute = currentCETTime.getMinutes();

	// Convert the start and end times to minutes since midnight
	const startMinutesSinceMidnight = startHour * 60 + startMinute;
	const endMinutesSinceMidnight = endHour * 60 + endMinute;

	// Convert the current time to minutes since midnight
	const currentMinutesSinceMidnight = currentHour * 60 + currentMinute;

	// Check if the current time is between the start and end times
	return currentMinutesSinceMidnight >= startMinutesSinceMidnight && currentMinutesSinceMidnight <= endMinutesSinceMidnight;
}

export function isDateBetween(d1: Date, start: Date, end: Date) {
	return ((d1.getTime() - start.getTime()) >= 0 && (end.getTime() - d1.getTime()) >= 0);
}

export function createDate(day: number, month: number, year: number) {
	return new Date(year, month - 1, day, 0, 0, 0, 0);
}

export function getTimeDifferenceInSeconds(date1: Date, date2: Date) {
	const time1 = Math.floor(date1.getTime() / 1000);
	const time2 = Math.floor(date2.getTime() / 1000);
	return time1 - time2;
}

export function formatTimeLeft(time: number) {
	const days = Math.floor(time / (24 * 3600));
	const hours = Math.floor((time % (24 * 3600)) / 3600);
	const minutes = Math.floor((time % 3600) / 60);
	const seconds = time % 60;
	return `${days} д ${hours.toString().padStart(2, '0')} ч ${minutes.toString().padStart(2, '0')} м ${seconds.toString().padStart(2, '0')} с`;
}

/**
 * Returns a new Date object representing 'n' days before the given date 'd1'.
 * @param d - The starting date
 * @param n - The number of days to go back
 */
export function getNDaysAgo(d: Date, n: number): Date {
	const newDate = new Date(d);
	newDate.setDate(newDate.getDate() - n);
	return newDate;
}

export function getWorkingHours(open_hours_dict: OpenHours) {
	let currentDaysOpenHours = '';
	let currentDays: Partial<typeof DAYS> = [];
	const result: Record<string, string> = {};

	for (const day of DAYS) {
		const openHours = open_hours_dict[day] || 'Закрыто';
		// Если текущей группы еще не было или часы в текущей группе не сходятся с текущим днем
		if (currentDaysOpenHours != openHours) {
			// финалим набранную группу
			if (currentDays.length > 0 && currentDaysOpenHours !== 'Закрыто') {
				result[getDaysRange(currentDays)] = currentDaysOpenHours;
			}
			// создаем новую группу
			currentDaysOpenHours = openHours;
			currentDays = [day];
		} else {
			currentDays.push(day);
		}
	}
	if (currentDays.length > 0 && currentDaysOpenHours !== 'Закрыто') {
		result[getDaysRange(currentDays)] = currentDaysOpenHours;
	}
	return result;
}
	
export function getDaysRange(days: Partial<typeof DAYS>): string {
	if (days.length === 0) {
		return '';
	}
	if (days.length === 1) {
		return days[0]!;
	}
	return `${days[0]} — ${days[days.length - 1]}`;
}

export function isWeekdayInMoscow(date: Date): boolean {
	const utc = date.getTime();
  
	// Смещение Москвы от UTC в миллисекундах (UTC+3)
	const moscowOffsetMs = 3 * 60 * 60 * 1000;
  
	// Создаём "виртуальную" дату в московском времени
	const moscowDate = new Date(utc + moscowOffsetMs);
  
	const dayOfWeek = moscowDate.getUTCDay(); // 0 - Sunday, 1 - Monday, ..., 6 - Saturday
  
	// Проверка: день недели от понедельника (1) до пятницы (5)
	return dayOfWeek >= 1 && dayOfWeek <= 5;
}
